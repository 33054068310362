<template>
    <app-header />
    <Suspense>
      <template #default>
        <AppOTP />
      </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
    <app-footer />
  </template>
  
  <script>
  import AppFooter from '../../components/layouts/AppFooter.vue'
  import AppHeader from '../../components/layouts/AppHeader.vue'
  import AppOTP from '../../components/Users/AppOTP.vue'
  import AppLoading from '../../components/Spinners/AppLoading.vue'
  import { Suspense } from 'vue'
  export default {
    components: { AppHeader, AppFooter, AppLoading, Suspense, AppOTP },
    name: 'Otp'
  }
  </script>
  