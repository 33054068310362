<template>
    <div class="login">
      <div class="container">
        <div class="row justify-content-center align-items-center d-flex vh-100">
          <div class="col-lg-5 mx-auto">
            <div class="osahan-login p-5 bg-dark">
              <div class="text-center mb-4">
                <router-link to="/"><img src="../../assets/image/yallagame.png" style="height: 50px;" alt=""></router-link>
                 <h5 class="font-weight-bold mt-3" style="color: #fff;"> OTP ادخل رمز التاكيد</h5>
              </div>
              <form  @submit.prevent="onOtp()">
                <div class="form-group">
                  <!-- <label class="mb-1" style="color: #fff;">رقم الهاتف</label> -->
                    <div class="position-relative icon-form-control">
                      <input type="text" v-model="otp" class="form-control" autocomplete="off" placeholder="رمز التاكيد OTP" onfocus="this.placeholder = ''" onblur="this.placeholder = 'رمز التاكيد OTP'" style="text-align: center;">
                    </div>
                </div>
                <!-- <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">تسجيل الدخول</button> -->
                <button class="btn btn-warning btn-lg btn-block text-uppercase">
                <span v-if="loading" class="px-1 arabickufi">تأكيد الاشتراك</span> &nbsp;
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                <span v-else class="arabickufi">تأكيد الاشتراك</span>
            </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
// import { useRouter, useRoute } from "vue-router";
// import { useCookie } from 'vue-cookie-next'
// import { ref } from 'vue';
export default {
    name: 'AppOTP',
    data() {
      return {
        otp: "",
        loading: false,
      };
    },
    methods: {
      async onOtp() {
        const toast = useToast();

        const OTP = this.otp;
        const Regex =  new RegExp(/^\d{4,6}$/gm);
        if (OTP === '') {
              toast.error("رمز التاكيد فارغ");
            } else if (!Regex.test(OTP)) {
              toast.error("خطأ في رمز التحقق");
            } else {
              if (this.$cookie.getCookie("operator") == 'stc') {
                // STC Call Api OTP
                this.loading = true;
                  await HTTPSPA.post(`StcsubmitVCode.php?msisdn=${this.$cookie.getCookie("mdn_Otp")}&serviceId=3754&vCode=${OTP}`).then((res) => {
                      if (res.data.status == 1 && res.data.message == '1020') {
                        //   this.$cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 * 7 });
                        //   this.$cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 * 7 });
                        //   this.$cookie.setCookie("showHaeder", 'stc', { expire: 60 * 60 * 24 * 7 });
                          this.$cookie.removeCookie('operator')
                          toast.success("تم اشتراكك في خدمة يلا قيم بنجاح");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '-1') {
                          toast.info("ليس لديك رصيد كافي");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '-100') {
                          toast.error("رمز التاكيد خطأ");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      }
                  }).catch((error) => {
                      console.log(error);
                  }).finally(() => {
                    this.loading = false;
                  });
              } else if (this.$cookie.getCookie("operator") == 'zain') {
                // Zain Call Api OTP
                this.loading = true;
                  await HTTPSPA.post(`ZainsubscribeUsingPinCode.php?msisdn=${this.$cookie.getCookie("mdn_Otp")}&serviceId=3595&pinCode=${OTP}`).then((res) => {
                      if (res.data.status == 1 && res.data.message == '000000,The operation succeeds.') {
                        //   this.$cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 });
                        //   this.$cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 });
                        //   this.$cookie.setCookie("showHaeder", 'zain', { expire: 60 * 60 * 24 });
                          this.$cookie.removeCookie('operator');
                          toast.success("تم اشتراكك في خدمة يلا قيم بنجاح");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '330040,The product has been subscribed.') {
                        //   this.$cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 });
                        //   cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 });
                        //   cookie.setCookie("showHaeder", 'zain', { expire: 60 * 60 * 24 });
                          this.$cookie.removeCookie('operator');
                          toast.info("لقد تم اشتراكك مسبقا في يلا قيم");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '-100') {
                          toast.error("رمز التاكيد خطأ");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '330158,The SMS verification code has expired.') {
                          toast.info("انتهت صلاحية رمز التحقق SMS.");
                          setTimeout(() => this.$router.push({ name: "Subscribes" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '330157,The SMS verification code is incorrect.') {
                          toast.info("رمز التحقق من الرسائل القصيرة غير صحيح");
                          setTimeout(() => this.$router.push({ name: "Subscribes" }), 3000);
                      } else if (res.data.status == 0 && res.data.message == 'pinCode Id is required') {
                          toast.error("رمز التاكيد فارغ");
                          // setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      }
                  }).catch((error) => {
                      console.log(error);
                  }).finally(() => {
                    this.loading = false;
                  });
              } else if (this.$cookie.getCookie("operator") == 'mobily') {
                  // Mobily Call Api OTP
                  this.loading = true;
                  await HTTPSPA.post(`MobilysubscribeUsingPinCode.php?msisdn=${this.$cookie.getCookie("mdn_Otp")}&serviceId=3596&pinCode=${OTP}`).then((res) => {
                      if (res.data.status == 1 && res.data.message == '000000,Operation succeeded.') {
                        //   this.$cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 });
                        //   this.$cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 });
                        //   this.$cookie.setCookie("showHaeder", 'mobily', { expire: 60 * 60 * 24 });
                          this.$cookie.removeCookie('operator');
                          toast.success("تم اشتراكك في خدمة يلا قيم بنجاح");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '10310,User already subscribed') {
                        //   this.$cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 });
                        //   this.$cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 });
                        //   this.$cookie.setCookie("showHaeder", 'mobily', { expire: 60 * 60 * 24 });
                          this.$cookie.removeCookie('operator');
                          toast.info("لقد تم اشتراكك مسبقا في يلا قيم");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '-100') {
                          toast.error("رمز التاكيد خطأ");
                          setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      } else if (res.data.status == 1 && res.data.message == '10312,The SMS verification code has expired.') {
                          toast.info("انتهت صلاحية رمز التحقق SMS.");
                          setTimeout(() => this.$router.push({ name: "Subscribes" }), 3000);
                      } else if (res.data.status == 0 && res.data.message == 'pinCode Id is required') {
                          toast.error("رمز التاكيد فارغ");
                          // setTimeout(() => this.$router.push({ path: "/" }), 3000);
                      }
                  }).catch((error) => {
                      console.log(error);
                  }).finally(() => {
                    this.loading = false;
                  });
              }
            }
      }
    }
    // setup() {
    //     const router = useRouter();
    //     const route = useRoute();
    //     const cookie = useCookie();
    //     const toast = useToast();
    //     const otp = ref('');
    //     const loading = ref(false);


    //     const onOtp = async () => {
    //         const OTP = otp.value;
    //         const Regex =  new RegExp(/^\d{4,6}$/gm);
    //         if (OTP === '') {
    //           toast.error("رمز التاكيد فارغ");
    //         } else if (!Regex.test(OTP)) {
    //           toast.error("خطأ في رمز التحقق");
    //         } else {
    //           if (cookie.getCookie("operator") == 'stc') {
    //             // STC Call Api OTP
    //               loading.value = true;
    //               await HTTPSPA.post(`StcsubmitVCode.php?msisdn=${cookie.getCookie("mdn_Otp")}&serviceId=3754&vCode=${OTP}`).then((res) => {
    //                   if (res.data.status == 1 && res.data.message == '1020') {
    //                     //   cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 * 7 });
    //                     //   cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 * 7 });
    //                     //   cookie.setCookie("showHaeder", 'stc', { expire: 60 * 60 * 24 * 7 });
    //                       cookie.removeCookie('operator')
    //                       toast.success("تم اشتراكك في خدمة يلا قيم بنجاح");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '-1') {
    //                       toast.info("ليس لديك رصيد كافي");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '-100') {
    //                       toast.error("رمز التاكيد خطأ");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   }
    //               }).catch((error) => {
    //                   console.log(error);
    //               }).finally(() => {
    //                   loading.value = false;
    //               });
    //           } else if (cookie.getCookie("operator") == 'zain') {
    //               // Zain Call Api OTP
    //               loading.value = true;
    //               await HTTPSPA.post(`ZainsubscribeUsingPinCode.php?msisdn=${cookie.getCookie("mdn_Otp")}&serviceId=3595&pinCode=${OTP}`).then((res) => {
    //                   if (res.data.status == 1 && res.data.message == '000000,The operation succeeds.') {
    //                     //   cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 });
    //                     //   cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 });
    //                     //   cookie.setCookie("showHaeder", 'zain', { expire: 60 * 60 * 24 });
    //                       cookie.removeCookie('operator');
    //                       toast.success("تم اشتراكك في خدمة يلا قيم بنجاح");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '330040,The product has been subscribed.') {
    //                     //   cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 });
    //                     //   cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 });
    //                     //   cookie.setCookie("showHaeder", 'zain', { expire: 60 * 60 * 24 });
    //                       cookie.removeCookie('operator');
    //                       toast.info("لقد تم اشتراكك مسبقا في يلا قيم");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '-100') {
    //                       toast.error("رمز التاكيد خطأ");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '330158,The SMS verification code has expired.') {
    //                       toast.info("انتهت صلاحية رمز التحقق SMS.");
    //                       setTimeout(() => router.push({ name: "Subscribes" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '330157,The SMS verification code is incorrect.') {
    //                       toast.info("رمز التحقق من الرسائل القصيرة غير صحيح");
    //                       setTimeout(() => router.push({ name: "Subscribes" }), 3000);
    //                   } else if (res.data.status == 0 && res.data.message == 'pinCode Id is required') {
    //                       toast.error("رمز التاكيد فارغ");
    //                       // setTimeout(() => router.push({ path: "/" }), 3000);
    //                   }
    //               }).catch((error) => {
    //                   console.log(error);
    //               }).finally(() => {
    //                   loading.value = false;
    //               });
    //           } else if (cookie.getCookie("operator") == 'mobily') {
    //               // Mobily Call Api OTP
    //               loading.value = true;
    //               await HTTPSPA.post(`MobilysubscribeUsingPinCode.php?msisdn=${cookie.getCookie("mdn_Otp")}&serviceId=3596&pinCode=${OTP}`).then((res) => {
    //                   if (res.data.status == 1 && res.data.message == '000000,Operation succeeded.') {
    //                     //   cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 });
    //                     //   cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 });
    //                     //   cookie.setCookie("showHaeder", 'mobily', { expire: 60 * 60 * 24 });
    //                       cookie.removeCookie('operator');
    //                       toast.success("تم اشتراكك في خدمة يلا قيم بنجاح");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '10310,User already subscribed') {
    //                     //   cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 });
    //                     //   cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 });
    //                     //   cookie.setCookie("showHaeder", 'mobily', { expire: 60 * 60 * 24 });
    //                       cookie.removeCookie('operator');
    //                       toast.info("لقد تم اشتراكك مسبقا في يلا قيم");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '-100') {
    //                       toast.error("رمز التاكيد خطأ");
    //                       setTimeout(() => router.push({ path: "/" }), 3000);
    //                   } else if (res.data.status == 1 && res.data.message == '10312,The SMS verification code has expired.') {
    //                       toast.info("انتهت صلاحية رمز التحقق SMS.");
    //                       setTimeout(() => router.push({ name: "Subscribes" }), 3000);
    //                   } else if (res.data.status == 0 && res.data.message == 'pinCode Id is required') {
    //                       toast.error("رمز التاكيد فارغ");
    //                       // setTimeout(() => router.push({ path: "/" }), 3000);
    //                   }
    //               }).catch((error) => {
    //                   console.log(error);
    //               }).finally(() => {
    //                   loading.value = false;
    //               });
    //           }
              
    //         }
    //     }

    //     return { otp, loading, onOtp}
    // }
}
</script>
<style scoped>
.btn-warning {
  color: #fff;
  border-color: rgb(131, 203, 206);
  background: #315b10 none repeat scroll 0% 0% !important
}

.bg-dark {
  background-image: linear-gradient(0deg,#315b10 0,#67bb27 );
}
</style>